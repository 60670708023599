import React from 'react';
import styled from 'styled-components';
import { Score } from '../score/Score';
import { Button } from '@homeproved/shared/ui';

export const Banner = styled(({ isMobile, isTablet, banner, ...restProps }) => (
  <div {...restProps} />
))`
  height: ${({ isMobile }) => (isMobile ? '16rem' : '35rem')};
  background-image: ${({ banner }) => banner && `url('${banner}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${({ isTablet, theme }) => !isTablet && theme.config.defaultBorderRadius};
  position: relative;
  margin: ${({ isMobile, isTablet }) =>
    isMobile ? '-2rem -2rem 2rem' : isTablet ? '-6rem -2rem 2rem' : ''};

  ${({ isTablet }) =>
    !isTablet &&
    `
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 12.5rem;
      bottom: 0;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
      opacity: 0.6;
    }
  `};
`;

export const BannerFree = styled(({ isMobile, isTablet, banner, ...restProps }) => (
  <div {...restProps} />
))`
  height: ${({ isTablet }) => (isTablet ? '9rem' : '15rem')};
  position: relative;
  margin: ${({ isMobile }) => (isMobile ? '2rem -2rem' : '0 0 2rem')};

  ${({ isTablet, banner, theme }) =>
    !banner &&
    !isTablet &&
    `
    &:after {
      content: '';
      background: ${isTablet ? theme.config.gradients.default : theme.palette.grey['100']};
      height: ${isTablet ? '6.6rem' : '11rem'};
      top: 50%;
      left: ${isTablet ? '-2rem' : '0'};
      right: ${isTablet ? '-2rem' : '0'};
      margin-top: ${isTablet ? '-3.3rem' : '-5.5rem'};
      position: absolute;
    }
  `};
`;

export const Content = styled(({ isMobile, banner, showScore, innerRef, ...restProps }) => (
  <div ref={innerRef} {...restProps} />
))`
  display: flex;
  align-items: ${({ banner }) => (banner ? 'flex-end' : 'center')};
  position: absolute;
  z-index: 1;
  top: 0;
  right: ${({ isMobile }) => isMobile && '2rem'};
  bottom: ${({ isMobile, banner }) => (banner ? (isMobile ? '2rem' : '3rem') : '0')};
  left: 0;
  padding-left: ${({ isMobile }) => (isMobile ? '2rem' : '3rem')};
  padding-right: ${({ showScore }) => (showScore ? '18rem' : '2rem')};
  width: 100%;
`;

export const Logo = styled(({ isMobile, isTablet, banner, noMargin, ...restProps }) => (
  <div {...restProps} />
))`
  width: ${({ isTablet }) => (isTablet ? '11rem' : '14rem')};
  height: ${({ isTablet }) => (isTablet ? '11rem' : '14rem')};
  margin: ${({ noMargin, isMobile }) => (noMargin ? '0' : isMobile ? '0 1rem 0 0' : '0 3rem 0 0')};
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  position: relative;
  z-index: 1;
  flex-shrink: 0;
`;

export const ImageWrapper = styled(({ isMobile, clear, ...restProps }) => <div {...restProps} />)`
  position: relative;
  z-index: 2;
  background-color: #fff;
  // padding: ${({ isMobile }) => (isMobile ? '1rem' : '2rem')};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: ${({ theme, clear }) => (!clear ? theme.config.defaultBoxShadow : 'none')};

  img {
    width: 100%;
    position: relative;
    z-index: 2;
  }
`;

export const Title = styled(({ isTablet, banner, dynamicTitleEnabled, ...restProps }) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h1 {...restProps} />
))`
  font-size: ${({ isTablet, dynamicTitleEnabled }) =>
    isTablet ? '2.5rem' : !dynamicTitleEnabled && '2.7rem'};
  font-weight: 700;
  color: ${({ isTablet, banner }) => (isTablet || !banner ? '' : '#fff')};
  margin-right: ${({ isTablet }) => !isTablet && '2rem'};
  margin-bottom: ${({ isTablet }) => isTablet && '.2rem'};
  max-height: 15rem;
`;

export const MobileTitle = styled(({ banner, isMobile, isTablet, ...restProps }) => (
  <div {...restProps} />
))`
  display: flex;
  align-items: center;
  margin-bottom: ${({ banner }) => (banner ? '2rem' : '0')};
  justify-content: space-between;
  margin: ${({ isMobile, isTablet }) => isTablet && !isMobile && '-3rem 0 2rem'};
`;

export const StyledA = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:visited {
    color: inherit;
  }
`;

export const MobileHeader = styled(({ claimed, ...restProps }) => <div {...restProps} />)`
  text-align: center;
  margin-top: ${({ claimed }) => (claimed ? '-7rem' : '-12rem')};
  padding-bottom: 7rem;
`;

export const Claimed = styled(({ isMobile, ...restProps }) => <div {...restProps} />)`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.4rem;
  background: ${({ theme }) => theme.config.ratings.green};
  border-radius: 50%;
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
  z-index: 9;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DesktopScore = styled(({ banner, ...restProps }) => <Score {...restProps} />)`
  position: absolute;
  right: 3rem;
  bottom: ${({ banner }) => (banner ? '-4rem' : '-1.5rem')};
  z-index: 2;
`;

export const MobileLocation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  margin: 0 0 2rem 0;
`;

export const MobileCity = styled.div`
  margin-left: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.6rem;
`;
export const HeaderContent = styled(({ hasBanner, innerRef, ...restProps }) => (
  <div ref={innerRef} {...restProps} />
))`
  display: flex;
  align-items: ${({ hasBanner }) => (hasBanner ? 'flex-end' : 'center')};
`;
