import { FC } from 'react';
import {
  AnonymousCompanyData,
  CompaniesApiFactory,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import { JSONLD } from '@homeproved/shared/util';
import { formatScore } from '@homeproved/shared/ui';

type Props = {
  company: AnonymousCompanyData;
};
export const CompanyStructuredData: FC<Props> = ({ company }) => {
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query: companyScore } = useQueryFetch(['companyScore', company.slug], () =>
    companiesApi.apiCompaniesCompanyScoreGet(company.id.toString())
  );

  if (companyScore?.data?.data?.score == null) return null;

  const score = formatScore(companyScore?.data?.data?.score);

  return (
    <JSONLD
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'Review',
          itemReviewed: {
            '@type': 'LocalBusiness',
            name: company.name,
            image: company.logo?.data.conversions?.['small'] ?? '/logo-default@2x.png',
            address: {
              '@type': 'PostalAddress',
              streetAddress: `${company.street} ${company.streetNr}`,
              addressLocality: company.city,
              postalCode: company.postalCode,
            },
            aggregateRating:
              score > 0
                ? {
                    '@type': 'AggregateRating',
                    ratingValue: score.toString() || '',
                    ratingCount: companyScore?.data?.data?.total?.toString() || '',
                    worstRating: '1',
                    bestRating: '5',
                  }
                : undefined,
          },
          author: {
            '@type': 'Organization',
            name: 'Homeproved',
          },
          publisher: {
            '@type': 'Organization',
            name: 'Homeproved.com',
          },
        },
      ]}
    />
  );
};
