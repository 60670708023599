import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Button,
  GoogleReviewCard,
  InfinitePagination,
  Modal,
  Pagination,
  ReviewCard,
} from '@homeproved/shared/ui';
import { useAnonymousCompany } from '@homeproved/shared/feature-company';
import { CompanyShell } from './CompanyShell';
import { CompanyData, PaginationMeta, Review, useQueryFetch } from '@homeproved/shared/data-access';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { useRouter } from 'next/router';
import {
  Wrapper,
  Title,
  StyledGrid,
  NoReviews,
  NoReviewsTitle,
  NoReviewsText,
} from './components/reviews/Atoms';
import { getInitials, objectToQueryString } from '@homeproved/shared/util';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import { CompanyAboutPage } from './CompanyAboutPage';

export type CompanyReviewPageProps = {
  slug: string;
  companyData: CompanyData;
  reviewsData: Review[];
  reviewCount: number;
  googleReviewCount: number;
  paginationData: PaginationMeta;
};

type ReviewData = {
  data: Review[];
  links: unknown;
  meta: unknown;
};

const PAGE_SIZE_DEFAULT = 10;

// first page of the reviews is with SSR for SEO , once user uses pagination we switch to client side data fetching

export const CompanyReviewPage: FC<CompanyReviewPageProps> = ({
  slug,
  companyData,
  reviewsData,
  reviewCount,
  googleReviewCount,
  paginationData,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const currentLanguage = useCurrentLanguage();
  const { getPath } = useLocalizedRoutes();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const { company, isSuccess, error } = useAnonymousCompany(slug);
  const [apiRouteInit, setApiRouteInit] = useState(false);
  const [page, setPage] = useState(1);
  const date = 'desc';
  const rating = '';
  const [reviewsLoaded, setReviewsLoaded] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [perPage, setPerPage] = useState(PAGE_SIZE_DEFAULT);
  const [apiRoute, setApiRoute] = useState('');
  const [reviews, setReviews] = useState(reviewsData);
  const [pagination, setPagination] = useState(paginationData);

  const getApiRoute = () => apiRoute + objectToQueryString({ date, rating, page, perPage });

  const { query } = useQueryFetch<ReviewData, unknown>(
    ['reviews', company?.id, currentLanguage],
    getApiRoute(),
    {
      options: {
        enabled: false,
      },
    }
  );

  useEffect(() => {
    if (!apiRouteInit && !!company) {
      setApiRoute(`/api/companies/${company?.id}/reviews/all?`);
      setApiRouteInit(true);
    }
  }, [apiRouteInit, company]);

  useEffect(() => {
    if (apiRouteInit && !reviewsLoaded) {
      if (page !== null) {
        query.refetch().then(({ status, data }) => {
          setReviews(data?.data);
          setPagination(data?.meta);
          setReviewsLoaded(status === 'success');
        });
      }
    }
  }, [apiRouteInit, reviewsLoaded, query, page, rating, date]);

  const navigateToReview = (slug: string, rid: number) => {
    router
      .push(
        getPath('/company/:slug/review/:rid', {
          slug,
          rid: rid.toString(),
        })
      )
      .then();
  };

  const goToPage = (page: number) => {
    setReviewsLoaded(false);
    setPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadMorePages = () => {
    setReviewsLoaded(false);
    setPage(1);
    setPerPage(perPage + 10);
  };

  if (error) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box mb={1}>
          <Typography variant="body1">{t('app.com.pages.company.notFoundMessage')}</Typography>
        </Box>
        <Button href={getPath('/')}>{t('app.com.pages.company.notFoundCta')}</Button>
      </Box>
    );
  }

  return isSuccess ? (
    <CompanyShell
      slug={slug}
      data={company}
      activeTab="reviews"
      hasReviews={reviews?.length > 0}
      reviewLength={pagination?.total}
    >
      <Wrapper isTablet={isTablet} hasReviews={reviews?.length > 0}>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          maxWidth={50}
          background={'gradient'}
        >
          {selectedReview ? (
            <Box p={3}>
              <GoogleReviewCard
                review={{
                  id: 1,
                  picture: selectedReview.data.authorImage,
                  isTranslation: false,
                  initials: getInitials(selectedReview.data.screenName),
                  date: selectedReview.data.createdAt,
                  rating: selectedReview.data.rating,
                  text: selectedReview.data.description,
                  title: 'Google Review',
                  companyId: company.id,
                  companySlug: company.slug,
                  screenName: selectedReview.data.screenName,
                }}
                readmoreButton={true}
                isMobile={isMobile}
                isTablet={isTablet}
                teaser={true}
                bigBalloon={true}
                equalHeight
              />
            </Box>
          ) : null}
        </Modal>
        {reviewsLoaded && reviews?.length > 0 ? (
          <>
            <Box display="flex" alignItems="center" mb={5}>
              {!isTablet && (
                <Title variant="h2">
                  {t('app.com.pages.company.reviews')}
                  <span>({reviewCount})</span>
                  <span>Homeproved</span>
                  {company.googleReviews ? (
                    <>
                      <span>({googleReviewCount})</span>
                      <span>Google</span>
                    </>
                  ) : null}
                </Title>
              )}
            </Box>
            <Grid container spacing={4}>
              {reviewsLoaded &&
                reviews.length > 0 &&
                reviews.map(
                  (review) =>
                    review.data && (
                      <StyledGrid item xs={12} sm={6} key={review.data.id}>
                        {review.data.origin === 'google' ? (
                          <GoogleReviewCard
                            review={{
                              id: review.data.id,
                              picture: review.data.authorImage,
                              isTranslation: false,
                              initials: getInitials(review.data.screenName),
                              date: review.data.createdAt,
                              rating: review.data.rating,
                              text: review.data.description,
                              title: 'Google Review',
                              companyId: company.id,
                              companySlug: company.slug,
                              screenName: review.data.screenName,
                            }}
                            readmoreButton={true}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            teaser={true}
                            bigBalloon={true}
                            equalHeight
                            onClick={() => {
                              setModalOpen(true);
                              setSelectedReview(review);
                            }}
                          />
                        ) : (
                          <ReviewCard
                            review={{
                              id: review.data.id,
                              files: review.data.files,
                              audio: review.data.audio,
                              video: review.data.video,
                              videoThumbnail: review.data.videoThumbnail,
                              picture: review.data.picture,
                              isTranslation: review.data.originalLanguage !== currentLanguage,
                              initials: review.data?.initials,
                              date: review.data.createdAt,
                              rating: review.data.rating,
                              title: review.data.title,
                              description: review.data.description,
                              companyId: review.data.relations.company.data.id,
                              companySlug: review.data.relations.company.data.slug,
                              screenName: review.data.screenName,
                            }}
                            getComPath={getPath}
                            href={getPath('/company/:slug/review/:rid', {
                              slug,
                              rid: review.data.id.toString(),
                            })}
                            readmoreButton={true}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            navigateToReview={navigateToReview}
                            teaser={true}
                            bigBalloon={true}
                            equalHeight
                          />
                        )}
                      </StyledGrid>
                    )
                )}
            </Grid>
            {reviewsLoaded &&
              pagination != null &&
              (isMobile ? (
                <InfinitePagination {...pagination} loadMore={loadMorePages} />
              ) : (
                <Pagination {...pagination} goToPage={goToPage} defaultPagesToShow={5} />
              ))}
          </>
        ) : null}
        {reviewsLoaded && reviews.length === 0 ? (
          <NoReviews isTablet={isTablet}>
            <NoReviewsTitle isTablet={isTablet}>
              {t('app.com.pages.company.companyShell.noReviews.title', { company: company.name })}
            </NoReviewsTitle>
            <NoReviewsText isTablet={isTablet}>
              {t('app.com.pages.company.companyShell.noReviews.text', { company: company.name })}
            </NoReviewsText>
            <Button
              href={getPath('/write-review/:slug', {
                slug: company.slug,
              })}
            >
              {t('app.com.pages.company.companyShell.writeReview')}
            </Button>
          </NoReviews>
        ) : null}
        {!reviewsLoaded ? <CircularProgress color={'primary'} /> : null}
      </Wrapper>
      <CompanyAboutPage slug={company.slug} />
    </CompanyShell>
  ) : null;
};
