import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InfinitePagination, LargeTile, Pagination } from '@homeproved/shared/ui';
import { useAnonymousCompany } from '@homeproved/shared/feature-company';
import { CompanyShell } from './CompanyShell';
import {
  PublicationsApiFactory,
  Review,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import Link from 'next/link';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

export type CompanyRealizationsPageProps = {
  slug: string;
};

type ReviewData = {
  data: Review[];
  links: unknown;
  meta: any;
};

// eslint-disable-next-line jsx-a11y/heading-has-content
const Title = styled(({ isTablet, ...restProps }) => <h2 {...restProps} />)`
  font-weight: 700;
  margin-bottom: 2rem;
  text-align: ${({ isTablet }) => isTablet && 'center'};

  span {
    font-weight: 400;
  }
`;

const Realizations = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.7rem;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm + 'px'}) {
    justify-content: center;
  }
  > * {
    flex: 0 0 100%;
    margin: 0.7rem;
    max-width: 32rem;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.values.sm + 'px'}) {
      flex: 0 0 calc(33.33% - 1.4rem);
    }
  }
`;

const StyledLargeTile = styled(LargeTile)`
  height: 100%;
`;

const StyledA = styled.a`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:visited {
    color: inherit;
  }
`;

export const CompanyRealizationsPage: FC<CompanyRealizationsPageProps> = ({ slug }) => {
  const { t } = useTranslation();
  const { getPath } = useLocalizedRoutes();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const { company, isSuccess } = useAnonymousCompany(slug);
  const currentLanguage = useCurrentLanguage();
  const { query: reviewsQuery } = useQueryFetch<ReviewData, unknown>(
    ['reviews', company?.id, currentLanguage],
    `/api/companies/${company?.id}/reviews`,
    {
      options: {
        enabled: company?.id != null,
      },
    }
  );
  const hasReviews = reviewsQuery.data?.data?.length > 0;
  const reviewLength = reviewsQuery.data?.meta?.total

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);

  const publicationsApi = useApiFactory(PublicationsApiFactory);
  const {
    query: { data: publications, refetch, isSuccess: publicationsSuccess },
  } = useQueryFetch(['companyPublicationsGet', currentLanguage, slug], () =>
    publicationsApi.apiCompaniesCompanyPublicationsGet(slug, page, perPage)
  );

  useEffect(() => {
    refetch();
  }, [page, perPage, refetch]);

  return isSuccess && publicationsSuccess ? (
    <CompanyShell slug={slug} data={company} activeTab={'realizations'} hasReviews={hasReviews} reviewLength={reviewLength}>
      <Title isTablet={isTablet}>
        {isTablet ? (
          <>
            {t('app.com.pages.company.ourRealizationsMobile')} {company.name}{' '}
            <span>({publications['data'].length})</span>
          </>
        ) : (
          t('app.com.pages.company.ourRealizations')
        )}
      </Title>
      <Realizations>
        {publications.data.map((item, index) => {
          const link =
            item['data'].type === 'realisation'
              ? getPath('/company/:slug/realization/:rslug', {
                  slug,
                  rslug: item['data'].slug,
                })
              : getPath('/housing-advice/articles/:article', {
                  article: item['data'].slug,
                });
          return (
            <Link key={item['data'].id} href={link} passHref>
              <StyledA href={link}>
                <StyledLargeTile
                  key={`${item['data'].type}-${item['data'].id}`}
                  title={item['data'].type === 'realisation' ? item['data'].title : ''}
                  image={
                    item['data']?.cover?.data?.conversions?.['small'] ||
                    item['data']?.cover?.data?.original
                  }
                  description={
                    item['data'].type === 'realisation'
                      ? item['data']?.subtitle
                      : item['data']?.title
                  }
                  clickable
                />
              </StyledA>
            </Link>
          );
        })}
      </Realizations>
      {publications?.meta != null &&
        (isMobile ? (
          <InfinitePagination
            {...publications.meta}
            loadMore={() => setPerPage((perPage) => perPage + 9)}
          />
        ) : (
          <Pagination {...publications.meta} goToPage={setPage} defaultPagesToShow={5} />
        ))}
    </CompanyShell>
  ) : null;
};
