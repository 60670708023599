import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icons, Stars, SvgIcon } from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import {
  CompaniesApiFactory,
  CompanyData,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import { Socials } from '../socials/Socials';
import { ReportIssue } from '@homeproved/com/feature-report-issue';
import countries from 'i18n-iso-countries';
import dutch from 'i18n-iso-countries/langs/nl.json';
import french from 'i18n-iso-countries/langs/fr.json';
import english from 'i18n-iso-countries/langs/en.json';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import { Specializations } from '../../specializations/Specializations';
import { CompanyShellTab } from '../../../CompanyShell';
import { Box } from '@material-ui/core';
import { makeValidUrl } from '../helpers';
import { CtaOfferte } from '../cta-offerte/CtaOfferte';

export type ContactInfoProps = {
  slug: string;
  data: CompanyData;
  isMobile?: boolean;
  isTablet?: boolean;
  hasReviews: boolean;
  reviewLength: number;
  activeTab: CompanyShellTab;
};

const Wrapper = styled(({ isTablet, ...restProps }) => <div {...restProps} />)`
  margin-bottom: ${({ isTablet }) => (!isTablet ? '2rem' : '0')};
  padding: ${({ isTablet }) => (!isTablet ? '3rem 1rem 1.5rem 4rem' : '0 2rem')};
  border: ${({ theme, isTablet }) => !isTablet && `0.2rem solid ${theme.palette.grey['A200']}`};
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
`;

const InfoWrapper = styled.div`
  margin-top: 3rem;
`;

const Value = styled.span`
  margin-left: 0.5rem;
`;

const Icon = styled(SvgIcon)`
  margin-right: 1rem;
`;

const Website = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  text-decoration: underline;
  font-weight: 700;
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  font-size: 1.3rem;
  &:hover {
    text-decoration: none;
  }
`;

const linkStyle = css`
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;

  &:hover,
  &:visited {
    color: inherit;
  }
`;

const ContactLink = styled.a`
  ${linkStyle};

  &:hover,
  &:visited {
    text-decoration: underline;
  }
`;

const ReviewLabel = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const ContactInfo: FC<ContactInfoProps> = ({
  slug,
  data,
  isMobile = false,
  isTablet = false,
  hasReviews,
  reviewLength,
  activeTab,
}) => {
  const { t } = useTranslation();
  const currentLanguage = useCurrentLanguage();
  const [premiumProfile, setPremiumProfile] = useState<boolean>(false);

  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query: companyScore } = useQueryFetch(['companyScore', slug], () =>
    companiesApi.apiCompaniesCompanyScoreGet(data.id.toString())
  );

  useEffect(() => {
    if (!data) return;
    if (data.relations?.subscription?.data?.features?.premiumProfile) setPremiumProfile(true);
  }, [data]);

  // Register country translations
  countries.registerLocale(dutch);
  countries.registerLocale(french);
  countries.registerLocale(english);

  return (
    <>
      <Wrapper isTablet={isTablet}>
        <Title>{data.name}</Title>
        <Stars showRating count={companyScore.data?.data?.score ?? 0} size={2.5} />
        <ReviewLabel>
          {hasReviews ? (
            <>
              <strong>{reviewLength}</strong>&nbsp;
              {`${
                reviewLength > 1
                  ? t('app.com.pages.company.companyShell.xReviews')
                  : t('app.com.pages.company.companyShell.xReview')
              }`}
            </>
          ) : (
            t('app.noReviews')
          )}
        </ReviewLabel>
      </Wrapper>
      <CtaOfferte
        companyId={data.id}
        companyName={data.name}
        companySlug={data.slug}
        companyEmail={data.email}
        sectors={data?.relations?.sectors}
      />
      <Wrapper isTablet={isTablet}>
        <Title>{t('app.com.pages.company.companyShell.contactInfo')}</Title>
        <InfoWrapper>
          {(!!data.street || !!data.streetNr || !!data.postalCode) && (
            <Info>
              <Icon icon={Icons.LOCATION_OUTLINE} size={1.8} />
              <div>
                {!!data.street && `${data.street} ${data.streetNr || ''}`}
                {!!data.street && <br />}
                {`${data.postalCode ?? ''} ${!!data.city && data.city} ${
                  !!data.country && countries.getName(data.country, currentLanguage)
                }`}
              </div>
            </Info>
          )}
          {!!data.phone && (
            <Info>
              <Icon icon={Icons.PHONE} size={1.8} />
              <ContactLink href={`tel:${data.phone}`}>{`Tel: ${data.phone}`}</ContactLink>
            </Info>
          )}
          {!!data.email && (
            <Info>
              <Icon icon={Icons.EMAIL} size={1.8} />
              <ContactLink href={`mailto:${data.email}`}>{data.email}</ContactLink>
            </Info>
          )}
          {!!data.vat && (
            <Info>
              <strong>{`${t('app.com.pages.company.companyShell.vat')}:`}</strong>
              <Value>{data.vat}</Value>
            </Info>
          )}
          {!!data.foundedAt && (
            <Info>
              <strong>{`${t('app.com.pages.company.companyShell.activeSince')}:`}</strong>
              <Value>{data.foundedAt}</Value>
            </Info>
          )}
          {!!data.url && (
            <Info>
              <Website href={makeValidUrl(data.url)} target={'_blank'}>
                {t('app.com.pages.company.companyShell.website')}
              </Website>
            </Info>
          )}
        </InfoWrapper>
        <Socials data={data} isMobile={isMobile} />
        {!premiumProfile && activeTab !== 'about' && (
          <Specializations
            specializations={data.relations.sectors}
            mobile={isMobile}
            oneColumn={true}
          />
        )}
        <Box style={{ transform: 'translateX(-2rem)' }}>
          <ReportIssue companyId={String(data.id)} isMobile={isMobile} />
        </Box>
      </Wrapper>
    </>
  );
};
