import React, { FC } from 'react';
import styled from 'styled-components';
import { CompanyData } from '@homeproved/shared/data-access';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

export interface CertificatesProps {
  data: CompanyData;
  mobile?: boolean;
}

const Wrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  display: flex;
  margin-top: 3rem;
  ${({ mobile }) => mobile && `justify-content: center;`}
`;

const Certificate = styled(Typography)`
  font-weight: 600;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  text-align: center;
  span {
    display: block;
  }
`;
const IconHolder = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  margin: 0 auto 1rem;
  border-radius: 50%;
  border: 0.1rem solid ${({ theme }) => theme.palette.grey['500']};
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CertificateIcon = styled.img`
  flex-shrink: 0;
  display: block;
`;

export const Certificates: FC<CertificatesProps> = ({ data, mobile }) => {
  const { t } = useTranslation();

  return (
    <Wrapper mobile={mobile}>
      {!!data.vca && (
        <Box pl={1} pr={1}>
          <IconHolder>
            <CertificateIcon src="/vca.svg" alt="vca" />
          </IconHolder>
          <Certificate variant="body1">VCA</Certificate>
        </Box>
      )}
      {!!data.iso9001 && (
        <Box pl={1} pr={1}>
          <IconHolder>
            <CertificateIcon src="/iso.svg" alt="iso" />
          </IconHolder>
          <Certificate variant="body1">
            ISO 9001 <span>{t('app.com.pages.company.companyShell.quality')}</span>
          </Certificate>
        </Box>
      )}
      {!!data.iso14001 && (
        <Box pl={1} pr={1}>
          <IconHolder>
            <CertificateIcon src="/iso.svg" alt="iso" />
          </IconHolder>
          <Certificate variant="body1">
            ISO 14001 <div>{t('app.com.pages.company.companyShell.environment')}</div>
          </Certificate>
        </Box>
      )}
      {!!data.atg && (
        <Box pl={1} pr={1}>
          <IconHolder>
            <CertificateIcon src="/atg2.png" alt="atg" />
          </IconHolder>
          <Certificate variant="body1">ATG</Certificate>
        </Box>
      )}
    </Wrapper>
  );
};
