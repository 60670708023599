import React, { FC, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LeadForms, Sector } from '@homeproved/shared/data-access';

type CtaOfferteProps = {
  isTablet?: boolean;
  companyId: number;
  companyName: string;
  companySlug: string;
  companyEmail: string;
  sectors: Sector[];
};

const ButtonBoxOfferte = styled(({ isTablet, ...restProps }) => <div {...restProps} />)`
  display: ${({ isTablet }) => (isTablet ? 'inline-block' : 'block')};
  width: ${({ isTablet }) => (isTablet ? 'auto' : '100%')};
  background: linear-gradient(to right, rgb(217, 2, 58) 0%, rgb(239, 140, 63) 100%);
  border-radius: 24px;
  color: #ffffff;
  text-align: center;
  padding: 0 10px;
`;

const ToggleButtons = styled(({ isTablet, ...restProps }) => <div {...restProps} />)`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-weight: 700;
  font-size: ${({ isTablet }) => (isTablet ? '14px' : '18px')};
  padding: 10px 18px;
  cursor: pointer;
  text-transform: uppercase;
`;

const SlideContainer = styled.div<{ isVisible: boolean }>`
  overflow: hidden;
  max-height: ${({ isVisible }) => (isVisible ? '500px' : '0')};
  transition: max-height 0.4s ease-in-out;
`;

const OfferteButtonBox = styled(({ isTablet, ...restProps }) => <div {...restProps} />)`
  button {
    font-family: ${({ theme }) => theme.config.fonts.PTSans};
    font-weight: 700;
    font-size: 14px !important;
    text-transform: uppercase;
    background: #ffffff;
    width: 100%;
    color: #333333 !important;
    padding: 5px 0;
    margin: 4px 0 !important;
    border: none;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: #dff0ef;
    }
  }
`;

const OfferteButtonInfo = styled.div`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-style: italic;
  font-size: 12px;
  color: #ffffff;
  padding: 8px 0 15px;
`;

export const CtaOfferte: FC<CtaOfferteProps> = ({
  isTablet = false,
  companyId,
  companyName,
  companySlug,
  companyEmail,
  sectors
}) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [leadforms, setLeadforms] = useState<LeadForms[]>([]);

  const toggleVisibility = () => setIsVisible(!isVisible);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://server.fillout.com/embed/v1/';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const fetchLeadforms = async () => {
      const fetchedLeadforms = sectors.map((sector) => sector.data?.leadforms || []).flat();
      setLeadforms(fetchedLeadforms);
    };

    fetchLeadforms();
  }, [sectors]);

  if (!leadforms || leadforms.length === 0) {
    return null;
  }

  return (
    <Box pt={isTablet ? 3 : 0} pb={isTablet ? 0 : 3}>
      <ButtonBoxOfferte isTablet={isTablet}>
        <ToggleButtons isTablet={isTablet} onClick={toggleVisibility}>
          {t('app.com.pages.company.companyShell.ctaOfferte.toggle')}
        </ToggleButtons>
        <SlideContainer isVisible={isVisible}>
          {leadforms.map((leadform, index) => (
            <OfferteButtonBox
              key={index}
              isTablet={isTablet}
              data-fillout-id={leadform.data.formId}
              data-fillout-button-text={leadform.data.name}
              data-fillout-embed-type="popup"
              data-fillout-dynamic-resize=""
              data-fillout-button-color="#D40733"
              data-fillout-inherit-parameters=""
              data-fillout-popup-size="medium"
              data-companyid={companyId}
              data-companyname={companyName}
              data-companyslug={companySlug}
              data-companymail={companyEmail}
              data-sectorid={leadform.data.sectorId}
              data-affiliate={leadform.data.affiliate}
              data-affiliateref={leadform.data.affiliateRef}
            />
          ))}
          <OfferteButtonInfo>
            {t('app.com.pages.company.companyShell.ctaOfferte.info')}
          </OfferteButtonInfo>
        </SlideContainer>
      </ButtonBoxOfferte>
    </Box>
  );
};
