import React, { FC } from 'react';
import styled from 'styled-components';
import { SvgIcon, useTheme } from '@material-ui/core';
import { CompaniesApiFactory, useApiFactory, useQueryFetch } from '@homeproved/shared/data-access';
import { useTranslation } from 'react-i18next';
import {Stars, formatScore} from "@homeproved/shared/ui";

type ScoreProps = {
  id: number;
  slug: string;
  max?: number;
  className?: string;
  size?: 'small' | 'large';
  color?:string;
};

const Wrapper = styled(({ size, ...restProps }) => <div {...restProps} />)`
  position: relative;
  width: ${({ size }) => (size === 'large' ? '13.5rem' : '10rem')};
`;

const ForegroundHomeShape = styled(({ size, ...restProps }) => <SvgIcon {...restProps} />)`
  width: ${({ size }) => (size === 'large' ? '13.5rem' : '10rem')};
  height: auto;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const BackgroundHomeShape = styled(({ size, ...restProps }) => <SvgIcon {...restProps} />)`
  width: ${({ size }) => (size === 'large' ? '14.5rem' : '11rem')};
  height: auto;
  text-align: center;
  margin: -0.7rem 0 0 -0.5rem;
`;

const Rating = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  font-weight: 700;
  align-items: baseline;
`;

const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ScoreNumber = styled(({ size, ...restProps }) => <span {...restProps} />)`
  font-size: ${({ size }) => (size === 'large' ? '4.5rem' : '3.2rem')};
  font-family: ${({ theme }) => theme.config.fonts.CabritoExtended};
  font-weight: 700;
  line-height: ${({ size }) => (size === 'large' ? '4.5rem' : '3.2rem')};
`;

const Max = styled(({ size, ...restProps }) => <span {...restProps} />)`
  font-size: ${({ size }) => (size === 'large' ? '2rem' : '1rem')};
  align-self: flex-end;
  padding: ${({ size }) => (size === 'large' ? '0 0 0 0.5rem' : '0 0 0.2rem')};
  font-family: ${({ theme }) => theme.config.fonts.CabritoExtended};
`;

const Reviews = styled(({ size, ...restProps }) => <div {...restProps} />)`
  color: #fff;
  font-size: ${({ size }) => (size === 'large' ? '1.5rem' : '1.1rem')};
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  text-align: center;
`;

export const Score: FC<ScoreProps> = ({ id, max = 5, className, size = 'small', slug, color }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query: companyScore } = useQueryFetch(['companyScore', slug], () =>
    companiesApi.apiCompaniesCompanyScoreGet(id.toString())
  );
  const score = formatScore(companyScore?.data?.data?.score);

  return (
    companyScore?.data?.data != null && (
      <Wrapper className={className} size={size}>
        <ForegroundHomeShape viewBox="0 0 84 74.9" size={size}>
          <path
            fill={color ? color : score > 0 ? theme.palette.grey[800] : theme.palette.grey['200']}
            d="M81.9 26.2L44.7.9c-1.9-1.3-4.4-1.2-6.3 0L2 26.4c-1.2.8-2 2.2-2 3.7v44.8h84v-45c0-1.5-.8-2.9-2.1-3.7z"
          />
        </ForegroundHomeShape>
        <BackgroundHomeShape viewBox="0 0 84 74.9" size={size}>
          <path
            fill={'#FFF'}
            d="M81.9 26.2L44.7.9c-1.9-1.3-4.4-1.2-6.3 0L2 26.4c-1.2.8-2 2.2-2 3.7v44.8h84v-45c0-1.5-.8-2.9-2.1-3.7z"
          />
        </BackgroundHomeShape>
        <Rating>
          <ScoreWrapper>
            <ScoreNumber size={size}>{score}</ScoreNumber>
            <Max size={size}>/ {max}</Max>
          </ScoreWrapper>
          <Reviews size={size}>{`${companyScore.data.data.total} ${
            parseInt(companyScore.data.data.total) > 1
              ? t('app.com.pages.company.companyShell.xReviews')
              : t('app.com.pages.company.companyShell.xReview')
          }`}</Reviews>
        </Rating>
        <Stars count={companyScore?.data?.data?.score} size={2.5} />
      </Wrapper>
    )
  );
};
