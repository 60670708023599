import { FC } from 'react';
import {
  AnonymousCompanyData,
  CompaniesApiFactory,
  ReviewData,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import { JSONLD } from '@homeproved/shared/util';
import { formatScore } from '@homeproved/shared/ui';

type Props = {
  review: ReviewData;
  company: AnonymousCompanyData;
};
export const ReviewStructuredData: FC<Props> = ({ review, company }) => {
  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query: companyScore } = useQueryFetch(['companyScore', company.slug], () =>
    companiesApi.apiCompaniesCompanyScoreGet(company.id.toString())
  );

  const score = formatScore(companyScore?.data?.data?.score);
  const reviewRating = formatScore(review?.rating);

  return (
    <JSONLD
      schema={[
        {
          '@context': 'http://schema.org',
          '@type': 'Review',
          itemReviewed: {
            '@type': 'LocalBusiness',
            name: company.name,
            image: company.logo?.data.conversions?.['small'] ?? '/logo-default@2x.png',
            address: {
              '@type': 'PostalAddress',
              streetAddress: `${company.street} ${company.streetNr}`,
              addressLocality: company.city,
              postalCode: company.postalCode,
            },
            aggregateRating:
              score > 0
                ? {
                    '@type': 'AggregateRating',
                    ratingValue: score.toString() || '',
                    ratingCount: companyScore?.data?.data?.total?.toString() || '',
                    worstRating: '1',
                    bestRating: '5',
                  }
                : undefined,
          },
          reviewRating: {
            '@type': 'Rating',
            ratingValue: reviewRating.toString() || '',
          },
          reviewBody: review.description,
          author: {
            '@type': 'Person',
            name: review.screenName,
          },
          publisher: {
            '@type': 'Organization',
            name: 'Homeproved.com',
          },
        },
      ]}
    />
  );
};
