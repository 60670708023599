import React, { FC } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { AnonymousCompanyData } from '@homeproved/shared/data-access';
import { ImageWrapper, Score } from '@homeproved/com/feature-company';

import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { Claimed, Logo, MobileCity, MobileLocation } from './components/company-shell/header/Atoms';
import { Button, IconButton, Icons, Initials, SvgIcon } from '@homeproved/shared/ui';
import { CompanyLogo } from '@homeproved/shared/feature-reviews';
import { getInitials } from '@homeproved/shared/util';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const BigButton = styled(Button)`
  width: 320px;
  height: 75px;
  font-size: 1.4rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  svg:first-child {
    font-size: 2.5rem;
    left: 2.5rem;
  }
`;

const BigButtonLight = styled(Button).attrs({ iconColor: '#D9023A' })`
  width: 320px;
  height: 75px;
  font-size: 1.4rem;
  border-radius: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.palette.primary.main};
  background: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.grey['800']};

  svg:first-child {
    font-size: 2.5rem;
    left: 2.5rem;
  }
`;

export type CompanyQrLandingProps = {
  slug: string;
  data: AnonymousCompanyData;
  claimed?: boolean;
};

export const CompanyQrLandingPage: FC<CompanyQrLandingProps> = ({
  slug,
  data,
  claimed = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const { getPath } = useLocalizedRoutes();
  const { t } = useTranslation();

  return data ? (
    <Box textAlign="center">
      {data.logo ? (
        <Box display="flex" p={1} justifyContent="center">
          <Logo noMargin isMobile={isMobile} isTablet={isTablet} banner={false}>
            {claimed && (
              <Claimed isMobile={isMobile}>
                <IconButton
                  icon={Icons.CHECKMARK}
                  size={1}
                  tooltip={t('app.com.pages.companySearch.mainSection.tooltipClaimed')}
                  variant="transparent"
                  tooltipPlacement={isMobile ? 'bottom' : 'right'}
                />
              </Claimed>
            )}
            <ImageWrapper isMobile={isMobile}>
              <CompanyLogo>
                {!data.logo ? (
                  <Initials fontSize={5}>{getInitials(data.name)}</Initials>
                ) : (
                  <img
                    src={data.logo?.data?.conversions?.['small'] || data.logo?.data?.original}
                    alt={data.name}
                  />
                )}
              </CompanyLogo>
            </ImageWrapper>
          </Logo>
        </Box>
      ) : null}
      <h1>{data.name}</h1>
      <MobileLocation>
        <SvgIcon icon={Icons.LOCATION_SOLID} size={1.5} />
        <MobileCity>{data.city}</MobileCity>
      </MobileLocation>
      <Box display="flex" p={1} justifyContent="center">
        <Score size={'large'} slug={data.slug} id={data.id} />
      </Box>
      <Box justifyContent="center">
        <Box mt={4} display="flex" flexDirection="column">
          <BigButton
            size={'large'}
            icon={Icons.PENCIL}
            variant={'gradient'}
            href={getPath('/write-review/:slug', {
              slug: data.slug,
            })}
          >
            {t('app.com.pages.company.companyShell.writeReview')}
          </BigButton>
          <BigButtonLight
            size={'large'}
            icon={Icons.FEEDBACK}
            variant={'gradient'}
            href={getPath('/company/:slug/reviews', { slug: data.slug })}
          >
            {t('app.com.pages.company.companyShell.readReviews', 'lees klantenbeoordelingen')}
          </BigButtonLight>
        </Box>
      </Box>
    </Box>
  ) : null;
};
