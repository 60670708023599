import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Sector, SectorDescendant } from '@homeproved/shared/data-access';

export type SpecializationsProps = {
  specializations: Sector[];
  mobile?: boolean;
  omitTitle?: boolean;
  oneColumn?: boolean;
};

const List = styled(({ mobile, oneColumn, ...restProps }) => <ul {...restProps} />)`
  ${({ mobile, oneColumn }) => !mobile && !oneColumn && `columns: 2;`}
  max-width: 50rem;
`;

const Specialization = styled.li`
  break-inside: avoid;
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  padding-right: 1rem;
`;

const SubSpecialization = styled.div`
  font-weight: 400;
  margin-bottom: 0.5rem;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 2rem;
`;

const Label = styled.div`
  margin-bottom: 0.5rem;
`;

export const Specializations: FC<SpecializationsProps> = ({
  specializations,
  mobile = false,
  omitTitle = false,
  oneColumn = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!omitTitle && <Title>{t('app.com.pages.company.activities')}</Title>}
      <List mobile={mobile} oneColumn={oneColumn}>
        {specializations.map((item, index) => (
          <Specialization key={index}>
            <Label>{item.data.name}</Label>
            {!!item.data.descendants && (
              <>
                {item.data.descendants.map((subItem: SectorDescendant, index) => (
                  <SubSpecialization key={index}>{subItem.data.name}</SubSpecialization>
                ))}
              </>
            )}
          </Specialization>
        ))}
      </List>
    </>
  );
};
