import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  SvgIcon,
  Icons,
  IconButton,
  useWindowSize,
  Initials,
  Stars,
} from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import {
  CompaniesApiFactory,
  CompanyData,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import {
  Banner,
  BannerFree,
  Content,
  Logo,
  ImageWrapper,
  Title,
  MobileHeader,
  Claimed,
  DesktopScore,
  MobileLocation,
  MobileCity,
  HeaderContent,
} from './Atoms';
import { useLocalizedRoutes as useComLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { useLocalizedRoutes as useProLocalizedRoutes } from '@homeproved/pro/feature-localized-routes';
import { CompanyShellTab } from '../../../CompanyShell';
import { Box } from '@material-ui/core';
import ReactFitText from 'react-fittext';
import { getInitials } from '@homeproved/shared/util';
import { CompanyLogo } from '@homeproved/shared/feature-reviews';
import { CtaOfferte } from '../cta-offerte/CtaOfferte';

export interface HeaderProps {
  data: CompanyData;
  claimed?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  hasReviews?: boolean;
  reviewLength?: number;
  activeTab: CompanyShellTab;
}

export const Header: FC<HeaderProps> = ({
  data,
  claimed = false,
  isMobile,
  isTablet = false,
  hasReviews = false,
  reviewLength = false,
  activeTab,
}) => {
  const { t } = useTranslation();
  const { getPath: getComPath } = useComLocalizedRoutes();
  const { getPath: getProPath } = useProLocalizedRoutes();
  const [premiumProfile, setPremiumProfile] = useState<boolean>(false);
  const [enableDynamicTitle, setEnableDynamicTitle] = useState(false);
  const headerContentRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();
  const { width: windowWidth } = useWindowSize(true);

  const companiesApi = useApiFactory(CompaniesApiFactory);
  const { query: companyScore } = useQueryFetch('companyScore', () =>
    companiesApi.apiCompaniesCompanyScoreGet(data?.id?.toString(), {
      options: {
        enabled: !!data,
      },
    })
  );

  const vakman2021 =
    data?.relations?.internalTags?.some((e) => e?.data?.slug === 'vakman2021') ?? false;

  useEffect(() => {
    if (!data) return;
    if (data.relations?.subscription?.data?.features?.premiumProfile) setPremiumProfile(true);
  }, [data]);

  useEffect(() => {
    if (headerContentRef.current && contentRef.current) {
      if (headerContentRef.current.offsetWidth >= contentRef.current.offsetWidth - 381) {
        setEnableDynamicTitle(true);
      } else {
        setEnableDynamicTitle(false);
      }
    }
  }, [headerContentRef, contentRef, windowWidth]);

  const ConditionalWrapper = ({ banner, wrapper, children }) =>
    !banner ? (
      <BannerFree
        isMobile={isMobile}
        isTablet={isTablet}
        banner={premiumProfile ? (data.cover ? data.cover.data.original : false) : false}
      >
        {children}
        {vakman2021 && (
          <img className="vakman-image-free" src={'/hp_vakman_202101.png'} alt="Vakman 2021" />
        )}
      </BannerFree>
    ) : (
      wrapper(children)
    );

  return (
    <>
      <ConditionalWrapper
        banner={premiumProfile ? !!data.cover : false}
        wrapper={(children) => (
          <Banner
            isMobile={isMobile}
            isTablet={isTablet}
            banner={data.cover ? data.cover.data.original : false}
          >
            {vakman2021 && (
              <img className="vakman-image" src={'/hp_vakman_202101.png'} alt="Vakman 2021" />
            )}
            {children}
          </Banner>
        )}
      >
        {!isTablet && (
          <>
            <Content
              isMobile={isMobile}
              banner={premiumProfile ? !!data.cover : false}
              showScore={hasReviews}
              innerRef={contentRef}
            >
              <Logo isTablet={isTablet}>
                <ImageWrapper isMobile={isMobile}>
                  <CompanyLogo>
                    {!data.logo ? (
                      <Initials fontSize={7} background>
                        {getInitials(data.name)}
                      </Initials>
                    ) : (
                      <img
                        src={data.logo?.data?.conversions?.['small'] || data.logo?.data?.original}
                        alt={data.name}
                      />
                    )}
                  </CompanyLogo>
                </ImageWrapper>
              </Logo>
              <HeaderContent hasBanner={premiumProfile && !!data.cover} innerRef={headerContentRef}>
                {enableDynamicTitle ? (
                  <ReactFitText maxFontSize={27}>
                    <Title
                      isTablet={isTablet}
                      dynamicTitleEnabled={enableDynamicTitle}
                      banner={premiumProfile ? !!data.cover : false}
                    >
                      {data.name}
                    </Title>
                  </ReactFitText>
                ) : (
                  <Title isTablet={isTablet} banner={premiumProfile ? !!data.cover : false}>
                    {data.name}
                  </Title>
                )}

                <Button
                  href={getComPath('/write-review/:slug', {
                    slug: data.slug,
                  })}
                >
                  {t('app.com.pages.company.companyShell.writeReview')}
                </Button>
              </HeaderContent>
            </Content>
            <DesktopScore
              id={data.id}
              slug={data.slug}
              size={'large'}
              banner={premiumProfile ? !!data.cover : false}
            />
          </>
        )}
      </ConditionalWrapper>
      {isTablet && (
        <MobileHeader claimed={claimed}>
          <Box
            display="flex"
            alignItems="end"
            flexWrap="wrap"
            justifyContent={data.logo ? 'start' : 'center'}
          >
            {data.logo ? (
              <Logo
                isMobile={isMobile}
                isTablet={isTablet}
                banner={premiumProfile ? !!data.cover : false}
              >
                {claimed && (
                  <Claimed isMobile={isMobile}>
                    <IconButton
                      icon={Icons.CHECKMARK}
                      size={1.3}
                      tooltip={t('app.com.pages.companySearch.mainSection.tooltipClaimed')}
                      variant="transparent"
                      tooltipPlacement={isMobile ? 'bottom' : 'right'}
                    />
                  </Claimed>
                )}
                <ImageWrapper isMobile={isMobile}>
                  <CompanyLogo>
                    {!data.logo ? (
                      <Initials fontSize={5}>{getInitials(data.name)}</Initials>
                    ) : (
                      <img
                        src={data.logo?.data?.conversions?.['small'] || data.logo?.data?.original}
                        alt={data.name}
                      />
                    )}
                  </CompanyLogo>
                </ImageWrapper>
              </Logo>
            ) : null}
            <Box textAlign="start">
              <Stars showRating count={companyScore.data?.data?.score ?? 0} size={2.5} />
              <Box fontSize="1.2rem">
                {hasReviews ? (
                  <>
                    <strong>{reviewLength}</strong>&nbsp;
                    {`${
                      reviewLength > 1
                        ? t('app.com.pages.company.companyShell.xReviews')
                        : t('app.com.pages.company.companyShell.xReview')
                    }`}
                  </>
                ) : (
                  t('app.noReviews')
                )}
              </Box>
            </Box>
          </Box>
          {activeTab !== 'realizations' && (
            <Title isTablet={isTablet} banner={premiumProfile ? !!data.cover : false}>
              {data.name}
            </Title>
          )}
          <MobileLocation>
            <SvgIcon icon={Icons.LOCATION_SOLID} size={1.5} />
            <MobileCity>{data.city}</MobileCity>
          </MobileLocation>
          {hasReviews && (
            <>
              <Button
                href={getComPath('/write-review/:slug', {
                  slug: data.slug,
                })}
              >
                {t('app.com.pages.company.companyShell.writeReview')}
              </Button>
            </>
          )}
          <CtaOfferte
            isTablet={isTablet}
            companyId={data.id}
            companyName={data.name}
            companySlug={data.slug}
            companyEmail={data.email}
            sectors={data?.relations?.sectors}
          />
        </MobileHeader>
      )}
    </>
  );
};
